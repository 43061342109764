.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  background: transparent
    linear-gradient(
      180deg,
      rgba(233, 31, 99, 0.75) 0%,
      rgba(204, 17, 80, 0.75) 100%
    )
    0% 0% no-repeat padding-box;
}

.petCareCardHolder:hover .overlay {
  opacity: 1;
}
