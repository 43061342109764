.customScrollBar::-webkit-scrollbar-track {
  background-color: #ffffff;
}

.customScrollBar::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #ffffff;
}

.customScrollBar::-webkit-scrollbar-thumb {
  background-color: #e81e61;
  border-radius: 2px;
}
